// @ts-nocheck
import Vue from "vue";
import CardAutocomplete from "../../../../components/Content/CardAutocomplete.vue";
import { isRequired } from "@/services/rule-services";
// @ts-ignore
import CardActions from "@/components/Content/CardAction.vue";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
import { getError } from "@/utils/resolveObjectArray";
import { ModelsDataCreate } from "@/interfaces/models";
import { mapActions} from "vuex";
import { isEmpty } from "lodash";

export default Vue.extend({
	name: "Overview",
	props: {
		is_edit: {
			type: Boolean,
			default: false,
		},
		canActive: {
			type: Boolean,
			default: false,
		}
	},
	components: {
		CardAutocomplete,
		CardActions,
	},
	data: () => ({
		title: "OverviewModels",
		valid: true,
		models: {
			campaign_id: null,
			metric: null
		} as ModelsDataCreate,
		showActionButtons: [] as string[],
		filters:{
			impression: ">0"
		},
		resources:{
			campaigns: [],
			metrics: []
		}
	}),
	created() {
		this.$nextTick(async() => {

		});
	},
	mounted() {
		this.$nextTick(async() => {
			await this.setBtnShows();
		});
	},
	computed: {
		hasID() {
			return Boolean(this.$route.params.id > 0);
		},
		isEdit(): Boolean {
			return this.hasID;
		},
		getRules() {
			return {
				isRequired
			};
		},
        getErrors() {
			return this.$store.state.proccess.errors;
		},
		getCampaigns(){
			return this.resources.campaigns;
		},
		getMetrics(){
			return this.resources.metrics;
		}
	},
	methods: {

		...mapActions("loading", ["setLoadingData"]),
		...mapActions("models", ["metricsList", "create"]),
		...mapActions("campaign", ["list", "all"]),
		...mapActions("proccess", ["setLoadingField"]),

		async setBtnShows() {
			
			let buttons: string[] = [];

			this.showActionButtons = buttons;

			buttons.push("save");
			buttons.push("cancel");

			this.showActionButtons = buttons;

			return buttons;
		},

		async validate() {
			let form = this.$refs.form;
			const valid = await form.validate();
			return await valid;
		},

		async handleAction(action: { type: any }) {
			switch (action.type) {
				case "save":
					await this.handleSubmit();
					break;

				case "cancel":
					this.handleCancel();
					break;
			}
		},

		async fetchCampaign(){
			if(!isEmpty(this.resources.campaigns)) return;
			await this.setLoadingField(TypeLoading.loading);
			await this.list({filters: this.filters})
			.then(async(res) => {
				this.resources.campaigns = res;
				await this.setLoadingField();
			})
			.catch(async(err) => {
				await this.setLoadingField();
			});
		},

		async fetchMetrics(){
			if(!isEmpty(this.resources.metrics)) return;
			await this.setLoadingField(TypeLoading.loading);
			await this.metricsList()
			.then(async(res) => {
				this.resources.metrics = res;
				await this.setLoadingField();
			})
			.catch(async(err) => {
				await this.setLoadingField();
			});
		},

		async handleSubmit(){
			if (!(await this.validate())) return;
			await this.setLoadingData(TypeLoading.loading);
			await this.create({data: this.models})
			.then(async (response) =>{
				await this.setLoadingData();
			})
			.catch(async (err) => {
				await this.setLoadingData();
			});
		},

        getError(index: any) {
			return getError(this.getErrors, index);
		},

        tooltipData(params: any) {
			return {
				icon: "mdi-information-outline",
				text: params,
				to: "",
				right: params?.right ?? true,
			};
		},

		handleCancel() {
			this.$router.push({ name: "ModelsIndex" });
		},
    },
	watch: {},
});